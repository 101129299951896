import React, { useState, useMemo, forwardRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type ComponentProps } from '@Components';

// SSR/Tests => true, Client => false
// eslint-disable-next-line @typescript-eslint/dot-notation
const defaultValue = typeof window === 'undefined' || process.env['NODE_ENV'] === 'test';

type UseLazyHydrationReturnValue = [
  () => void,
  ReturnType<typeof forwardRef<HTMLElement, ComponentProps & { children: React.ReactNode }>>,
  boolean,
];

export const useLazyHydration = (
  initialValue = false,
): UseLazyHydrationReturnValue => {
  const [ isHydrated, setIsHydrated ] = useState(initialValue || defaultValue);

  return useMemo(
    () => [
      () => !isHydrated && setIsHydrated(true),

      forwardRef(({ as: Component = 'div', className, children }, ref) => {
        if (isHydrated) {
          return (
            <Component
              ref={ref}
              className={className}
            >
              {children}
            </Component>
          );
        }

        return (
          <Component
            ref={ref}
            className={className}
            suppressHydrationWarning
            dangerouslySetInnerHTML={{ __html: '' }}
          >
            {children}
          </Component>
        );
      }),

      isHydrated,
    ],
    [ isHydrated ],
  );
};
